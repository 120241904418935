import React, { useState, useEffect } from 'react';
import { styled, AppBar as MuiAppBar, Toolbar, IconButton, Typography, Menu, MenuItem,Button } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import logo from '../logo/logo-no-background3.png';
import { collection, getDocs,getDoc, doc, query, where, addDoc } from 'firebase/firestore';
//mport { db, functions } from '../firebase-config'; 
import { getFunctions, httpsCallable } from 'firebase/functions';
const drawerWidth = 240;
const defaultTheme = createTheme({
    palette: {
      primary: {
        main: '#304f6d', 
      },
    },
  });
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Navbar() {
  const [currentUser, setCurrentUser] = useState(null);
  const [userDisplayName, setUserDisplayName] = useState('');
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user);
      setUserDisplayName(user.email)
    });
    return () => unsubscribe();
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    signOut(auth).catch(error => {
      console.error("Sign out error", error);
    });
  };

  // const fetchCustomerPortalLink = async () => {
  //   setLoading(true);
  //   try {
  //     const currentUser = getAuth().currentUser;
  //     if (!currentUser) {
  //       console.log('No user logged in');
  //       setLoading(false);
  //       return;
  //     }
  
  //     const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-ezj4-createPortalLink');
  //     const { data } = await createPortalLink({
  //       returnUrl: window.location.origin,
  //       locale: 'auto',
  //     });
  
  //     window.location.assign(data.url); // Redirect the user to the Stripe dashboard
  //   } catch (error) {
  //     console.error('Error fetching customer portal link:', error);
  //   }
  
  //   setLoading(false);

    
  // };

  const fetchCustomerPortalLink = async () => {
    // setLoading(true);
    // try {
    //     const currentUser = getAuth().currentUser;
    //     if (!currentUser) {
    //         console.error('No user logged in');
    //         setLoading(false);
    //         return;
    //     }

    //     const token = await currentUser.getIdToken(true);  // Force refresh the token
    //     const response = await fetch('https://us-central1-stripe-cde79.cloudfunctions.net/ext-firestore-stripe-payments-ezj4-createPortalLink', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${token}`  // Pass the ID token in the Authorization header
    //         },
    //         body: JSON.stringify({
    //             returnUrl: window.location.origin,
    //             locale: 'auto',
    //         })
    //     });

    //     if (!response.ok) {
    //         throw new Error(`HTTP error! Status: ${response.status}`);
    //     }

    //     const data = await response.json();
    //     window.location.href = data.url;  // Redirect to the Stripe portal
    // } catch (error) {
    //     console.error('Error fetching customer portal link:', error);
    // } finally {
    //     setLoading(false);
    // }
};

  return (
    <ThemeProvider theme={defaultTheme}>
    <AppBar position="absolute" open={false}>
      <Toolbar sx={{ pr: '24px' }}>
        <a href="https://www.pandorabot.io" target="_blank" rel="noopener noreferrer">
          <img src={logo} alt="Logo" style={{ height: 50, marginRight: '20px' }} />
        </a>
        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
          
        </Typography>
        <IconButton
          size="large"
          edge="end"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <AccountCircleIcon />
        </IconButton>

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem disabled>
            <Typography variant="subtitle1">Hello {userDisplayName}</Typography>
          </MenuItem>
          <MenuItem onClick={fetchCustomerPortalLink}>Dashboard</MenuItem>
          <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
    </ThemeProvider>
  );
  
}
