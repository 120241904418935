

// import React from 'react';
// import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
// import SignIn from './SignIn';
// import SignUp from './SignUp';
// import Dashboard from './dashboard/Dashboard';
// import { useAuth } from './useAuth'; 
// import LoadingScreen from './LoadingScreen'; 
// import CalendarList from './dashboard/CalendarView'
// import OAuth from './OAuth';
// import OAuthCallback from './OAuthCallback';
// import UnifiedComponent from './UnifiedComponents';
// import Navbar from './components/Navbar';

// function App() {
//   const { currentUser, isLoading } = useAuth();

//   if (isLoading) {
//     return <LoadingScreen />; // Use the LoadingScreen component instead of a text message
// }

//   return (
//     <BrowserRouter>
//       {currentUser && <Navbar />} 
//       <Routes>
//         <Route path="/login" element={currentUser ? <Navigate to="/" /> : <SignIn />} />
//         <Route path="/register" element={currentUser ? <Navigate to="/" /> : <SignUp />} />
//         <Route path="/" element={currentUser ? <Dashboard /> : <Navigate to="/login" />} />
//         <Route path="/calendar" element={currentUser ? <CalendarList /> : <Navigate to="/login" />} />
//         <Route path="/oauth" element={<OAuth />} />
        
//         <Route path="/oauthcallback" element={<OAuthCallback />} />
//       </Routes>
//     </BrowserRouter>
//   );
// }

// export default App;

// // function App() {
// //   return (
// //     <BrowserRouter>
// //       <Routes>
// //         <Route path="/signin" element={<SignIn />} />
// //         <Route path="/signup" element={<SignUp />} />
// //         <Route path="/" element={<Dashboard />} />
// //       </Routes>
// //     </BrowserRouter>
// //   );
// // }

// // export default App;

// App.js

import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Dashboard from './dashboard/Dashboard';
import { useAuth } from './useAuth';
import LoadingScreen from './LoadingScreen';
import CalendarList from './dashboard/CalendarView';
import CalendarListLocal from './dashboard/CalendarListLocal';
import OAuth from './OAuth';
import OAuthCallback from './OAuthCallback';
import Navbar from './components/Navbar';
import AccessDenied from './accessDenied';

function App() {
  const { currentUser, isLoading, checkSubscriptionStatus } = useAuth();
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
  const [isSubscriptionChecked, setIsSubscriptionChecked] = useState(false);
  const [appLoading, setAppLoading] = useState(true);

  // useEffect(() => {
  //   const checkSubscription = async () => {
  //     if (currentUser) {
  //       try {
  //         const isActive = await checkSubscriptionStatus(currentUser.uid);
  //         setIsSubscriptionActive(isActive);
  //         setIsSubscriptionChecked(true);
  //         console.log('Subscription status:', isActive);
  //       } catch (error) {
  //         console.error('Error checking subscription status:', error);
  //         setIsSubscriptionChecked(true);
  //       }
  //     } else {
  //       setIsSubscriptionChecked(true);
  //       console.log('No current user');
  //     }
  //   };

  //   checkSubscription();
  // }, [currentUser, checkSubscriptionStatus]);

  useEffect(() => {
    const checkSubscription = async () => {
      if (currentUser) {
        try {
          const isActive = await checkSubscriptionStatus(currentUser.uid);
          setIsSubscriptionActive(isActive);
          setIsSubscriptionChecked(true);
          console.log('Subscription status:', isActive);
        } catch (error) {
          console.error('Error checking subscription status:', error);
          setIsSubscriptionChecked(true);
        } finally {
          setAppLoading(false); // Set appLoading to false after the initial subscription check completes
        }
      } else {
        setIsSubscriptionChecked(true);
        console.log('No current user');
        setAppLoading(false); // Set appLoading to false even when no current user is found
      }
    };
  
    if (isLoading) {
      // If user authentication is still loading, ensure the app is still in loading state
      setAppLoading(true);
    } else {
      checkSubscription();
    }
  }, [currentUser, isLoading, checkSubscriptionStatus]);
  

  if (isLoading || !isSubscriptionChecked) {
    return <LoadingScreen />;
  }

  return (
    <>
      {appLoading ? (
        <LoadingScreen />
      ) : (
        <BrowserRouter>
          {currentUser && <Navbar />}
          <Routes>
            <Route path="/login" element={currentUser ? <Navigate to="/" /> : <SignIn />} />
            <Route path="/register" element={currentUser ? <Navigate to="/" /> : <SignUp />} />
            <Route path="/" element={currentUser ? <Dashboard /> : <Navigate to="/login" />} />
            <Route
              path="/calendar"
              element={
                currentUser ? (
                  isSubscriptionActive ? (
                    <CalendarList />
                  ) : (
                    <AccessDenied />
                  )
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route path="/oauth" element={<OAuth />} />
            <Route path="/oauthcallback" element={<OAuthCallback />} />
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
  

  // return (
  //   <BrowserRouter>
  //     {currentUser && <Navbar />}
  //     <Routes>
  //       <Route path="/login" element={currentUser ? <Navigate to="/" /> : <SignIn />} />
  //       <Route path="/register" element={currentUser ? <Navigate to="/" /> : <SignUp />} />
  //       <Route path="/" element={currentUser ? <Dashboard /> : <Navigate to="/login" />} />
  //       <Route
  //         path="/calendar"
  //         element={
  //           currentUser ? (
  //             isSubscriptionActive ? (
  //               <CalendarList />
  //               //<CalendarListLocal />
  //             ) : (
  //               <AccessDenied />
  //             )
  //           ) : (
  //             <Navigate to="/login" />
  //           )
  //         }
  //       />
  //       <Route path="/oauth" element={<OAuth />} />
  //       <Route path="/oauthcallback" element={<OAuthCallback />} />
  //     </Routes>
  //   </BrowserRouter>
  // );
}

export default App;