import React from 'react';
import { Link } from 'react-router-dom';
import './AccessDenied.css';

function AccessDenied() {
  return (
    <div className="access-denied-container">
      <div className="access-denied-content">
        <h2>Access Denied</h2>
        <p>You don't have an active subscription to access the calendar.</p>
        <p>Please subscribe to our service to unlock this feature.</p>
        <Link to="/" className="btn-go-back">
          Go Back to Dashboard
        </Link>
      </div>
    </div>
  );
}

export default AccessDenied;